html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[type="button"], [type="reset"], [type="submit"], button {
  -webkit-appearance: button;
}

[type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner, button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring, button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

@font-face {
  font-family: FontAwesome;
  src: url("fontawesome-webfont.3981e506.eot");
  src: url("fontawesome-webfont.3981e506.eot#iefix&v=4.7.0") format("embedded-opentype"), url("fontawesome-webfont.58488e7e.woff2") format("woff2"), url("fontawesome-webfont.ed962b83.woff") format("woff"), url("fontawesome-webfont.0caf0c90.ttf") format("truetype"), url("fontawesome-webfont.a9323ae9.svg#fontawesomeregular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

.fa {
  font: 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
}

.fa-lg {
  vertical-align: -15%;
  font-size: 1.33333em;
  line-height: .75em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-fw {
  width: 1.28571em;
  text-align: center;
}

.fa-ul {
  margin-left: 2.14286em;
  padding-left: 0;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  width: 2.14286em;
  text-align: center;
  position: absolute;
  top: .142857em;
  left: -2.14286em;
}

.fa-li.fa-lg {
  left: -1.85714em;
}

.fa-border {
  border: .08em solid #eee;
  border-radius: .1em;
  padding: .2em .25em .15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left {
  margin-right: .3em;
}

.fa.fa-pull-right {
  margin-left: .3em;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.fa.pull-left {
  margin-right: .3em;
}

.fa.pull-right {
  margin-left: .3em;
}

.fa-spin {
  animation: fa-spin 2s linear infinite;
}

.fa-pulse {
  animation: fa-spin 1s steps(8, end) infinite;
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    transform: rotate(359deg);
  }
}

.fa-rotate-90 {
  transform: rotate(90deg);
}

.fa-rotate-180 {
  transform: rotate(180deg);
}

.fa-rotate-270 {
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  transform: scale(1, -1);
}

:root .fa-flip-horizontal, :root .fa-flip-vertical, :root .fa-rotate-180, :root .fa-rotate-270, :root .fa-rotate-90 {
  filter: none;
}

.fa-stack {
  width: 2em;
  height: 2em;
  vertical-align: middle;
  line-height: 2em;
  display: inline-block;
  position: relative;
}

.fa-stack-1x, .fa-stack-2x {
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

.fa-glass:before {
  content: "";
}

.fa-music:before {
  content: "";
}

.fa-search:before {
  content: "";
}

.fa-envelope-o:before {
  content: "";
}

.fa-heart:before {
  content: "";
}

.fa-star:before {
  content: "";
}

.fa-star-o:before {
  content: "";
}

.fa-user:before {
  content: "";
}

.fa-film:before {
  content: "";
}

.fa-th-large:before {
  content: "";
}

.fa-th:before {
  content: "";
}

.fa-th-list:before {
  content: "";
}

.fa-check:before {
  content: "";
}

.fa-close:before, .fa-remove:before, .fa-times:before {
  content: "";
}

.fa-search-plus:before {
  content: "";
}

.fa-search-minus:before {
  content: "";
}

.fa-power-off:before {
  content: "";
}

.fa-signal:before {
  content: "";
}

.fa-cog:before, .fa-gear:before {
  content: "";
}

.fa-trash-o:before {
  content: "";
}

.fa-home:before {
  content: "";
}

.fa-file-o:before {
  content: "";
}

.fa-clock-o:before {
  content: "";
}

.fa-road:before {
  content: "";
}

.fa-download:before {
  content: "";
}

.fa-arrow-circle-o-down:before {
  content: "";
}

.fa-arrow-circle-o-up:before {
  content: "";
}

.fa-inbox:before {
  content: "";
}

.fa-play-circle-o:before {
  content: "";
}

.fa-repeat:before, .fa-rotate-right:before {
  content: "";
}

.fa-refresh:before {
  content: "";
}

.fa-list-alt:before {
  content: "";
}

.fa-lock:before {
  content: "";
}

.fa-flag:before {
  content: "";
}

.fa-headphones:before {
  content: "";
}

.fa-volume-off:before {
  content: "";
}

.fa-volume-down:before {
  content: "";
}

.fa-volume-up:before {
  content: "";
}

.fa-qrcode:before {
  content: "";
}

.fa-barcode:before {
  content: "";
}

.fa-tag:before {
  content: "";
}

.fa-tags:before {
  content: "";
}

.fa-book:before {
  content: "";
}

.fa-bookmark:before {
  content: "";
}

.fa-print:before {
  content: "";
}

.fa-camera:before {
  content: "";
}

.fa-font:before {
  content: "";
}

.fa-bold:before {
  content: "";
}

.fa-italic:before {
  content: "";
}

.fa-text-height:before {
  content: "";
}

.fa-text-width:before {
  content: "";
}

.fa-align-left:before {
  content: "";
}

.fa-align-center:before {
  content: "";
}

.fa-align-right:before {
  content: "";
}

.fa-align-justify:before {
  content: "";
}

.fa-list:before {
  content: "";
}

.fa-dedent:before, .fa-outdent:before {
  content: "";
}

.fa-indent:before {
  content: "";
}

.fa-video-camera:before {
  content: "";
}

.fa-image:before, .fa-photo:before, .fa-picture-o:before {
  content: "";
}

.fa-pencil:before {
  content: "";
}

.fa-map-marker:before {
  content: "";
}

.fa-adjust:before {
  content: "";
}

.fa-tint:before {
  content: "";
}

.fa-edit:before, .fa-pencil-square-o:before {
  content: "";
}

.fa-share-square-o:before {
  content: "";
}

.fa-check-square-o:before {
  content: "";
}

.fa-arrows:before {
  content: "";
}

.fa-step-backward:before {
  content: "";
}

.fa-fast-backward:before {
  content: "";
}

.fa-backward:before {
  content: "";
}

.fa-play:before {
  content: "";
}

.fa-pause:before {
  content: "";
}

.fa-stop:before {
  content: "";
}

.fa-forward:before {
  content: "";
}

.fa-fast-forward:before {
  content: "";
}

.fa-step-forward:before {
  content: "";
}

.fa-eject:before {
  content: "";
}

.fa-chevron-left:before {
  content: "";
}

.fa-chevron-right:before {
  content: "";
}

.fa-plus-circle:before {
  content: "";
}

.fa-minus-circle:before {
  content: "";
}

.fa-times-circle:before {
  content: "";
}

.fa-check-circle:before {
  content: "";
}

.fa-question-circle:before {
  content: "";
}

.fa-info-circle:before {
  content: "";
}

.fa-crosshairs:before {
  content: "";
}

.fa-times-circle-o:before {
  content: "";
}

.fa-check-circle-o:before {
  content: "";
}

.fa-ban:before {
  content: "";
}

.fa-arrow-left:before {
  content: "";
}

.fa-arrow-right:before {
  content: "";
}

.fa-arrow-up:before {
  content: "";
}

.fa-arrow-down:before {
  content: "";
}

.fa-mail-forward:before, .fa-share:before {
  content: "";
}

.fa-expand:before {
  content: "";
}

.fa-compress:before {
  content: "";
}

.fa-plus:before {
  content: "";
}

.fa-minus:before {
  content: "";
}

.fa-asterisk:before {
  content: "";
}

.fa-exclamation-circle:before {
  content: "";
}

.fa-gift:before {
  content: "";
}

.fa-leaf:before {
  content: "";
}

.fa-fire:before {
  content: "";
}

.fa-eye:before {
  content: "";
}

.fa-eye-slash:before {
  content: "";
}

.fa-exclamation-triangle:before, .fa-warning:before {
  content: "";
}

.fa-plane:before {
  content: "";
}

.fa-calendar:before {
  content: "";
}

.fa-random:before {
  content: "";
}

.fa-comment:before {
  content: "";
}

.fa-magnet:before {
  content: "";
}

.fa-chevron-up:before {
  content: "";
}

.fa-chevron-down:before {
  content: "";
}

.fa-retweet:before {
  content: "";
}

.fa-shopping-cart:before {
  content: "";
}

.fa-folder:before {
  content: "";
}

.fa-folder-open:before {
  content: "";
}

.fa-arrows-v:before {
  content: "";
}

.fa-arrows-h:before {
  content: "";
}

.fa-bar-chart-o:before, .fa-bar-chart:before {
  content: "";
}

.fa-twitter-square:before {
  content: "";
}

.fa-facebook-square:before {
  content: "";
}

.fa-camera-retro:before {
  content: "";
}

.fa-key:before {
  content: "";
}

.fa-cogs:before, .fa-gears:before {
  content: "";
}

.fa-comments:before {
  content: "";
}

.fa-thumbs-o-up:before {
  content: "";
}

.fa-thumbs-o-down:before {
  content: "";
}

.fa-star-half:before {
  content: "";
}

.fa-heart-o:before {
  content: "";
}

.fa-sign-out:before {
  content: "";
}

.fa-linkedin-square:before {
  content: "";
}

.fa-thumb-tack:before {
  content: "";
}

.fa-external-link:before {
  content: "";
}

.fa-sign-in:before {
  content: "";
}

.fa-trophy:before {
  content: "";
}

.fa-github-square:before {
  content: "";
}

.fa-upload:before {
  content: "";
}

.fa-lemon-o:before {
  content: "";
}

.fa-phone:before {
  content: "";
}

.fa-square-o:before {
  content: "";
}

.fa-bookmark-o:before {
  content: "";
}

.fa-phone-square:before {
  content: "";
}

.fa-twitter:before {
  content: "";
}

.fa-facebook-f:before, .fa-facebook:before {
  content: "";
}

.fa-github:before {
  content: "";
}

.fa-unlock:before {
  content: "";
}

.fa-credit-card:before {
  content: "";
}

.fa-feed:before, .fa-rss:before {
  content: "";
}

.fa-hdd-o:before {
  content: "";
}

.fa-bullhorn:before {
  content: "";
}

.fa-bell:before {
  content: "";
}

.fa-certificate:before {
  content: "";
}

.fa-hand-o-right:before {
  content: "";
}

.fa-hand-o-left:before {
  content: "";
}

.fa-hand-o-up:before {
  content: "";
}

.fa-hand-o-down:before {
  content: "";
}

.fa-arrow-circle-left:before {
  content: "";
}

.fa-arrow-circle-right:before {
  content: "";
}

.fa-arrow-circle-up:before {
  content: "";
}

.fa-arrow-circle-down:before {
  content: "";
}

.fa-globe:before {
  content: "";
}

.fa-wrench:before {
  content: "";
}

.fa-tasks:before {
  content: "";
}

.fa-filter:before {
  content: "";
}

.fa-briefcase:before {
  content: "";
}

.fa-arrows-alt:before {
  content: "";
}

.fa-group:before, .fa-users:before {
  content: "";
}

.fa-chain:before, .fa-link:before {
  content: "";
}

.fa-cloud:before {
  content: "";
}

.fa-flask:before {
  content: "";
}

.fa-cut:before, .fa-scissors:before {
  content: "";
}

.fa-copy:before, .fa-files-o:before {
  content: "";
}

.fa-paperclip:before {
  content: "";
}

.fa-floppy-o:before, .fa-save:before {
  content: "";
}

.fa-square:before {
  content: "";
}

.fa-bars:before, .fa-navicon:before, .fa-reorder:before {
  content: "";
}

.fa-list-ul:before {
  content: "";
}

.fa-list-ol:before {
  content: "";
}

.fa-strikethrough:before {
  content: "";
}

.fa-underline:before {
  content: "";
}

.fa-table:before {
  content: "";
}

.fa-magic:before {
  content: "";
}

.fa-truck:before {
  content: "";
}

.fa-pinterest:before {
  content: "";
}

.fa-pinterest-square:before {
  content: "";
}

.fa-google-plus-square:before {
  content: "";
}

.fa-google-plus:before {
  content: "";
}

.fa-money:before {
  content: "";
}

.fa-caret-down:before {
  content: "";
}

.fa-caret-up:before {
  content: "";
}

.fa-caret-left:before {
  content: "";
}

.fa-caret-right:before {
  content: "";
}

.fa-columns:before {
  content: "";
}

.fa-sort:before, .fa-unsorted:before {
  content: "";
}

.fa-sort-desc:before, .fa-sort-down:before {
  content: "";
}

.fa-sort-asc:before, .fa-sort-up:before {
  content: "";
}

.fa-envelope:before {
  content: "";
}

.fa-linkedin:before {
  content: "";
}

.fa-rotate-left:before, .fa-undo:before {
  content: "";
}

.fa-gavel:before, .fa-legal:before {
  content: "";
}

.fa-dashboard:before, .fa-tachometer:before {
  content: "";
}

.fa-comment-o:before {
  content: "";
}

.fa-comments-o:before {
  content: "";
}

.fa-bolt:before, .fa-flash:before {
  content: "";
}

.fa-sitemap:before {
  content: "";
}

.fa-umbrella:before {
  content: "";
}

.fa-clipboard:before, .fa-paste:before {
  content: "";
}

.fa-lightbulb-o:before {
  content: "";
}

.fa-exchange:before {
  content: "";
}

.fa-cloud-download:before {
  content: "";
}

.fa-cloud-upload:before {
  content: "";
}

.fa-user-md:before {
  content: "";
}

.fa-stethoscope:before {
  content: "";
}

.fa-suitcase:before {
  content: "";
}

.fa-bell-o:before {
  content: "";
}

.fa-coffee:before {
  content: "";
}

.fa-cutlery:before {
  content: "";
}

.fa-file-text-o:before {
  content: "";
}

.fa-building-o:before {
  content: "";
}

.fa-hospital-o:before {
  content: "";
}

.fa-ambulance:before {
  content: "";
}

.fa-medkit:before {
  content: "";
}

.fa-fighter-jet:before {
  content: "";
}

.fa-beer:before {
  content: "";
}

.fa-h-square:before {
  content: "";
}

.fa-plus-square:before {
  content: "";
}

.fa-angle-double-left:before {
  content: "";
}

.fa-angle-double-right:before {
  content: "";
}

.fa-angle-double-up:before {
  content: "";
}

.fa-angle-double-down:before {
  content: "";
}

.fa-angle-left:before {
  content: "";
}

.fa-angle-right:before {
  content: "";
}

.fa-angle-up:before {
  content: "";
}

.fa-angle-down:before {
  content: "";
}

.fa-desktop:before {
  content: "";
}

.fa-laptop:before {
  content: "";
}

.fa-tablet:before {
  content: "";
}

.fa-mobile-phone:before, .fa-mobile:before {
  content: "";
}

.fa-circle-o:before {
  content: "";
}

.fa-quote-left:before {
  content: "";
}

.fa-quote-right:before {
  content: "";
}

.fa-spinner:before {
  content: "";
}

.fa-circle:before {
  content: "";
}

.fa-mail-reply:before, .fa-reply:before {
  content: "";
}

.fa-github-alt:before {
  content: "";
}

.fa-folder-o:before {
  content: "";
}

.fa-folder-open-o:before {
  content: "";
}

.fa-smile-o:before {
  content: "";
}

.fa-frown-o:before {
  content: "";
}

.fa-meh-o:before {
  content: "";
}

.fa-gamepad:before {
  content: "";
}

.fa-keyboard-o:before {
  content: "";
}

.fa-flag-o:before {
  content: "";
}

.fa-flag-checkered:before {
  content: "";
}

.fa-terminal:before {
  content: "";
}

.fa-code:before {
  content: "";
}

.fa-mail-reply-all:before, .fa-reply-all:before {
  content: "";
}

.fa-star-half-empty:before, .fa-star-half-full:before, .fa-star-half-o:before {
  content: "";
}

.fa-location-arrow:before {
  content: "";
}

.fa-crop:before {
  content: "";
}

.fa-code-fork:before {
  content: "";
}

.fa-chain-broken:before, .fa-unlink:before {
  content: "";
}

.fa-question:before {
  content: "";
}

.fa-info:before {
  content: "";
}

.fa-exclamation:before {
  content: "";
}

.fa-superscript:before {
  content: "";
}

.fa-subscript:before {
  content: "";
}

.fa-eraser:before {
  content: "";
}

.fa-puzzle-piece:before {
  content: "";
}

.fa-microphone:before {
  content: "";
}

.fa-microphone-slash:before {
  content: "";
}

.fa-shield:before {
  content: "";
}

.fa-calendar-o:before {
  content: "";
}

.fa-fire-extinguisher:before {
  content: "";
}

.fa-rocket:before {
  content: "";
}

.fa-maxcdn:before {
  content: "";
}

.fa-chevron-circle-left:before {
  content: "";
}

.fa-chevron-circle-right:before {
  content: "";
}

.fa-chevron-circle-up:before {
  content: "";
}

.fa-chevron-circle-down:before {
  content: "";
}

.fa-html5:before {
  content: "";
}

.fa-css3:before {
  content: "";
}

.fa-anchor:before {
  content: "";
}

.fa-unlock-alt:before {
  content: "";
}

.fa-bullseye:before {
  content: "";
}

.fa-ellipsis-h:before {
  content: "";
}

.fa-ellipsis-v:before {
  content: "";
}

.fa-rss-square:before {
  content: "";
}

.fa-play-circle:before {
  content: "";
}

.fa-ticket:before {
  content: "";
}

.fa-minus-square:before {
  content: "";
}

.fa-minus-square-o:before {
  content: "";
}

.fa-level-up:before {
  content: "";
}

.fa-level-down:before {
  content: "";
}

.fa-check-square:before {
  content: "";
}

.fa-pencil-square:before {
  content: "";
}

.fa-external-link-square:before {
  content: "";
}

.fa-share-square:before {
  content: "";
}

.fa-compass:before {
  content: "";
}

.fa-caret-square-o-down:before, .fa-toggle-down:before {
  content: "";
}

.fa-caret-square-o-up:before, .fa-toggle-up:before {
  content: "";
}

.fa-caret-square-o-right:before, .fa-toggle-right:before {
  content: "";
}

.fa-eur:before, .fa-euro:before {
  content: "";
}

.fa-gbp:before {
  content: "";
}

.fa-dollar:before, .fa-usd:before {
  content: "";
}

.fa-inr:before, .fa-rupee:before {
  content: "";
}

.fa-cny:before, .fa-jpy:before, .fa-rmb:before, .fa-yen:before {
  content: "";
}

.fa-rouble:before, .fa-rub:before, .fa-ruble:before {
  content: "";
}

.fa-krw:before, .fa-won:before {
  content: "";
}

.fa-bitcoin:before, .fa-btc:before {
  content: "";
}

.fa-file:before {
  content: "";
}

.fa-file-text:before {
  content: "";
}

.fa-sort-alpha-asc:before {
  content: "";
}

.fa-sort-alpha-desc:before {
  content: "";
}

.fa-sort-amount-asc:before {
  content: "";
}

.fa-sort-amount-desc:before {
  content: "";
}

.fa-sort-numeric-asc:before {
  content: "";
}

.fa-sort-numeric-desc:before {
  content: "";
}

.fa-thumbs-up:before {
  content: "";
}

.fa-thumbs-down:before {
  content: "";
}

.fa-youtube-square:before {
  content: "";
}

.fa-youtube:before {
  content: "";
}

.fa-xing:before {
  content: "";
}

.fa-xing-square:before {
  content: "";
}

.fa-youtube-play:before {
  content: "";
}

.fa-dropbox:before {
  content: "";
}

.fa-stack-overflow:before {
  content: "";
}

.fa-instagram:before {
  content: "";
}

.fa-flickr:before {
  content: "";
}

.fa-adn:before {
  content: "";
}

.fa-bitbucket:before {
  content: "";
}

.fa-bitbucket-square:before {
  content: "";
}

.fa-tumblr:before {
  content: "";
}

.fa-tumblr-square:before {
  content: "";
}

.fa-long-arrow-down:before {
  content: "";
}

.fa-long-arrow-up:before {
  content: "";
}

.fa-long-arrow-left:before {
  content: "";
}

.fa-long-arrow-right:before {
  content: "";
}

.fa-apple:before {
  content: "";
}

.fa-windows:before {
  content: "";
}

.fa-android:before {
  content: "";
}

.fa-linux:before {
  content: "";
}

.fa-dribbble:before {
  content: "";
}

.fa-skype:before {
  content: "";
}

.fa-foursquare:before {
  content: "";
}

.fa-trello:before {
  content: "";
}

.fa-female:before {
  content: "";
}

.fa-male:before {
  content: "";
}

.fa-gittip:before, .fa-gratipay:before {
  content: "";
}

.fa-sun-o:before {
  content: "";
}

.fa-moon-o:before {
  content: "";
}

.fa-archive:before {
  content: "";
}

.fa-bug:before {
  content: "";
}

.fa-vk:before {
  content: "";
}

.fa-weibo:before {
  content: "";
}

.fa-renren:before {
  content: "";
}

.fa-pagelines:before {
  content: "";
}

.fa-stack-exchange:before {
  content: "";
}

.fa-arrow-circle-o-right:before {
  content: "";
}

.fa-arrow-circle-o-left:before {
  content: "";
}

.fa-caret-square-o-left:before, .fa-toggle-left:before {
  content: "";
}

.fa-dot-circle-o:before {
  content: "";
}

.fa-wheelchair:before {
  content: "";
}

.fa-vimeo-square:before {
  content: "";
}

.fa-try:before, .fa-turkish-lira:before {
  content: "";
}

.fa-plus-square-o:before {
  content: "";
}

.fa-space-shuttle:before {
  content: "";
}

.fa-slack:before {
  content: "";
}

.fa-envelope-square:before {
  content: "";
}

.fa-wordpress:before {
  content: "";
}

.fa-openid:before {
  content: "";
}

.fa-bank:before, .fa-institution:before, .fa-university:before {
  content: "";
}

.fa-graduation-cap:before, .fa-mortar-board:before {
  content: "";
}

.fa-yahoo:before {
  content: "";
}

.fa-google:before {
  content: "";
}

.fa-reddit:before {
  content: "";
}

.fa-reddit-square:before {
  content: "";
}

.fa-stumbleupon-circle:before {
  content: "";
}

.fa-stumbleupon:before {
  content: "";
}

.fa-delicious:before {
  content: "";
}

.fa-digg:before {
  content: "";
}

.fa-pied-piper-pp:before {
  content: "";
}

.fa-pied-piper-alt:before {
  content: "";
}

.fa-drupal:before {
  content: "";
}

.fa-joomla:before {
  content: "";
}

.fa-language:before {
  content: "";
}

.fa-fax:before {
  content: "";
}

.fa-building:before {
  content: "";
}

.fa-child:before {
  content: "";
}

.fa-paw:before {
  content: "";
}

.fa-spoon:before {
  content: "";
}

.fa-cube:before {
  content: "";
}

.fa-cubes:before {
  content: "";
}

.fa-behance:before {
  content: "";
}

.fa-behance-square:before {
  content: "";
}

.fa-steam:before {
  content: "";
}

.fa-steam-square:before {
  content: "";
}

.fa-recycle:before {
  content: "";
}

.fa-automobile:before, .fa-car:before {
  content: "";
}

.fa-cab:before, .fa-taxi:before {
  content: "";
}

.fa-tree:before {
  content: "";
}

.fa-spotify:before {
  content: "";
}

.fa-deviantart:before {
  content: "";
}

.fa-soundcloud:before {
  content: "";
}

.fa-database:before {
  content: "";
}

.fa-file-pdf-o:before {
  content: "";
}

.fa-file-word-o:before {
  content: "";
}

.fa-file-excel-o:before {
  content: "";
}

.fa-file-powerpoint-o:before {
  content: "";
}

.fa-file-image-o:before, .fa-file-photo-o:before, .fa-file-picture-o:before {
  content: "";
}

.fa-file-archive-o:before, .fa-file-zip-o:before {
  content: "";
}

.fa-file-audio-o:before, .fa-file-sound-o:before {
  content: "";
}

.fa-file-movie-o:before, .fa-file-video-o:before {
  content: "";
}

.fa-file-code-o:before {
  content: "";
}

.fa-vine:before {
  content: "";
}

.fa-codepen:before {
  content: "";
}

.fa-jsfiddle:before {
  content: "";
}

.fa-life-bouy:before, .fa-life-buoy:before, .fa-life-ring:before, .fa-life-saver:before, .fa-support:before {
  content: "";
}

.fa-circle-o-notch:before {
  content: "";
}

.fa-ra:before, .fa-rebel:before, .fa-resistance:before {
  content: "";
}

.fa-empire:before, .fa-ge:before {
  content: "";
}

.fa-git-square:before {
  content: "";
}

.fa-git:before {
  content: "";
}

.fa-hacker-news:before, .fa-y-combinator-square:before, .fa-yc-square:before {
  content: "";
}

.fa-tencent-weibo:before {
  content: "";
}

.fa-qq:before {
  content: "";
}

.fa-wechat:before, .fa-weixin:before {
  content: "";
}

.fa-paper-plane:before, .fa-send:before {
  content: "";
}

.fa-paper-plane-o:before, .fa-send-o:before {
  content: "";
}

.fa-history:before {
  content: "";
}

.fa-circle-thin:before {
  content: "";
}

.fa-header:before {
  content: "";
}

.fa-paragraph:before {
  content: "";
}

.fa-sliders:before {
  content: "";
}

.fa-share-alt:before {
  content: "";
}

.fa-share-alt-square:before {
  content: "";
}

.fa-bomb:before {
  content: "";
}

.fa-futbol-o:before, .fa-soccer-ball-o:before {
  content: "";
}

.fa-tty:before {
  content: "";
}

.fa-binoculars:before {
  content: "";
}

.fa-plug:before {
  content: "";
}

.fa-slideshare:before {
  content: "";
}

.fa-twitch:before {
  content: "";
}

.fa-yelp:before {
  content: "";
}

.fa-newspaper-o:before {
  content: "";
}

.fa-wifi:before {
  content: "";
}

.fa-calculator:before {
  content: "";
}

.fa-paypal:before {
  content: "";
}

.fa-google-wallet:before {
  content: "";
}

.fa-cc-visa:before {
  content: "";
}

.fa-cc-mastercard:before {
  content: "";
}

.fa-cc-discover:before {
  content: "";
}

.fa-cc-amex:before {
  content: "";
}

.fa-cc-paypal:before {
  content: "";
}

.fa-cc-stripe:before {
  content: "";
}

.fa-bell-slash:before {
  content: "";
}

.fa-bell-slash-o:before {
  content: "";
}

.fa-trash:before {
  content: "";
}

.fa-copyright:before {
  content: "";
}

.fa-at:before {
  content: "";
}

.fa-eyedropper:before {
  content: "";
}

.fa-paint-brush:before {
  content: "";
}

.fa-birthday-cake:before {
  content: "";
}

.fa-area-chart:before {
  content: "";
}

.fa-pie-chart:before {
  content: "";
}

.fa-line-chart:before {
  content: "";
}

.fa-lastfm:before {
  content: "";
}

.fa-lastfm-square:before {
  content: "";
}

.fa-toggle-off:before {
  content: "";
}

.fa-toggle-on:before {
  content: "";
}

.fa-bicycle:before {
  content: "";
}

.fa-bus:before {
  content: "";
}

.fa-ioxhost:before {
  content: "";
}

.fa-angellist:before {
  content: "";
}

.fa-cc:before {
  content: "";
}

.fa-ils:before, .fa-shekel:before, .fa-sheqel:before {
  content: "";
}

.fa-meanpath:before {
  content: "";
}

.fa-buysellads:before {
  content: "";
}

.fa-connectdevelop:before {
  content: "";
}

.fa-dashcube:before {
  content: "";
}

.fa-forumbee:before {
  content: "";
}

.fa-leanpub:before {
  content: "";
}

.fa-sellsy:before {
  content: "";
}

.fa-shirtsinbulk:before {
  content: "";
}

.fa-simplybuilt:before {
  content: "";
}

.fa-skyatlas:before {
  content: "";
}

.fa-cart-plus:before {
  content: "";
}

.fa-cart-arrow-down:before {
  content: "";
}

.fa-diamond:before {
  content: "";
}

.fa-ship:before {
  content: "";
}

.fa-user-secret:before {
  content: "";
}

.fa-motorcycle:before {
  content: "";
}

.fa-street-view:before {
  content: "";
}

.fa-heartbeat:before {
  content: "";
}

.fa-venus:before {
  content: "";
}

.fa-mars:before {
  content: "";
}

.fa-mercury:before {
  content: "";
}

.fa-intersex:before, .fa-transgender:before {
  content: "";
}

.fa-transgender-alt:before {
  content: "";
}

.fa-venus-double:before {
  content: "";
}

.fa-mars-double:before {
  content: "";
}

.fa-venus-mars:before {
  content: "";
}

.fa-mars-stroke:before {
  content: "";
}

.fa-mars-stroke-v:before {
  content: "";
}

.fa-mars-stroke-h:before {
  content: "";
}

.fa-neuter:before {
  content: "";
}

.fa-genderless:before {
  content: "";
}

.fa-facebook-official:before {
  content: "";
}

.fa-pinterest-p:before {
  content: "";
}

.fa-whatsapp:before {
  content: "";
}

.fa-server:before {
  content: "";
}

.fa-user-plus:before {
  content: "";
}

.fa-user-times:before {
  content: "";
}

.fa-bed:before, .fa-hotel:before {
  content: "";
}

.fa-viacoin:before {
  content: "";
}

.fa-train:before {
  content: "";
}

.fa-subway:before {
  content: "";
}

.fa-medium:before {
  content: "";
}

.fa-y-combinator:before, .fa-yc:before {
  content: "";
}

.fa-optin-monster:before {
  content: "";
}

.fa-opencart:before {
  content: "";
}

.fa-expeditedssl:before {
  content: "";
}

.fa-battery-4:before, .fa-battery-full:before, .fa-battery:before {
  content: "";
}

.fa-battery-3:before, .fa-battery-three-quarters:before {
  content: "";
}

.fa-battery-2:before, .fa-battery-half:before {
  content: "";
}

.fa-battery-1:before, .fa-battery-quarter:before {
  content: "";
}

.fa-battery-0:before, .fa-battery-empty:before {
  content: "";
}

.fa-mouse-pointer:before {
  content: "";
}

.fa-i-cursor:before {
  content: "";
}

.fa-object-group:before {
  content: "";
}

.fa-object-ungroup:before {
  content: "";
}

.fa-sticky-note:before {
  content: "";
}

.fa-sticky-note-o:before {
  content: "";
}

.fa-cc-jcb:before {
  content: "";
}

.fa-cc-diners-club:before {
  content: "";
}

.fa-clone:before {
  content: "";
}

.fa-balance-scale:before {
  content: "";
}

.fa-hourglass-o:before {
  content: "";
}

.fa-hourglass-1:before, .fa-hourglass-start:before {
  content: "";
}

.fa-hourglass-2:before, .fa-hourglass-half:before {
  content: "";
}

.fa-hourglass-3:before, .fa-hourglass-end:before {
  content: "";
}

.fa-hourglass:before {
  content: "";
}

.fa-hand-grab-o:before, .fa-hand-rock-o:before {
  content: "";
}

.fa-hand-paper-o:before, .fa-hand-stop-o:before {
  content: "";
}

.fa-hand-scissors-o:before {
  content: "";
}

.fa-hand-lizard-o:before {
  content: "";
}

.fa-hand-spock-o:before {
  content: "";
}

.fa-hand-pointer-o:before {
  content: "";
}

.fa-hand-peace-o:before {
  content: "";
}

.fa-trademark:before {
  content: "";
}

.fa-registered:before {
  content: "";
}

.fa-creative-commons:before {
  content: "";
}

.fa-gg:before {
  content: "";
}

.fa-gg-circle:before {
  content: "";
}

.fa-tripadvisor:before {
  content: "";
}

.fa-odnoklassniki:before {
  content: "";
}

.fa-odnoklassniki-square:before {
  content: "";
}

.fa-get-pocket:before {
  content: "";
}

.fa-wikipedia-w:before {
  content: "";
}

.fa-safari:before {
  content: "";
}

.fa-chrome:before {
  content: "";
}

.fa-firefox:before {
  content: "";
}

.fa-opera:before {
  content: "";
}

.fa-internet-explorer:before {
  content: "";
}

.fa-television:before, .fa-tv:before {
  content: "";
}

.fa-contao:before {
  content: "";
}

.fa-500px:before {
  content: "";
}

.fa-amazon:before {
  content: "";
}

.fa-calendar-plus-o:before {
  content: "";
}

.fa-calendar-minus-o:before {
  content: "";
}

.fa-calendar-times-o:before {
  content: "";
}

.fa-calendar-check-o:before {
  content: "";
}

.fa-industry:before {
  content: "";
}

.fa-map-pin:before {
  content: "";
}

.fa-map-signs:before {
  content: "";
}

.fa-map-o:before {
  content: "";
}

.fa-map:before {
  content: "";
}

.fa-commenting:before {
  content: "";
}

.fa-commenting-o:before {
  content: "";
}

.fa-houzz:before {
  content: "";
}

.fa-vimeo:before {
  content: "";
}

.fa-black-tie:before {
  content: "";
}

.fa-fonticons:before {
  content: "";
}

.fa-reddit-alien:before {
  content: "";
}

.fa-edge:before {
  content: "";
}

.fa-credit-card-alt:before {
  content: "";
}

.fa-codiepie:before {
  content: "";
}

.fa-modx:before {
  content: "";
}

.fa-fort-awesome:before {
  content: "";
}

.fa-usb:before {
  content: "";
}

.fa-product-hunt:before {
  content: "";
}

.fa-mixcloud:before {
  content: "";
}

.fa-scribd:before {
  content: "";
}

.fa-pause-circle:before {
  content: "";
}

.fa-pause-circle-o:before {
  content: "";
}

.fa-stop-circle:before {
  content: "";
}

.fa-stop-circle-o:before {
  content: "";
}

.fa-shopping-bag:before {
  content: "";
}

.fa-shopping-basket:before {
  content: "";
}

.fa-hashtag:before {
  content: "";
}

.fa-bluetooth:before {
  content: "";
}

.fa-bluetooth-b:before {
  content: "";
}

.fa-percent:before {
  content: "";
}

.fa-gitlab:before {
  content: "";
}

.fa-wpbeginner:before {
  content: "";
}

.fa-wpforms:before {
  content: "";
}

.fa-envira:before {
  content: "";
}

.fa-universal-access:before {
  content: "";
}

.fa-wheelchair-alt:before {
  content: "";
}

.fa-question-circle-o:before {
  content: "";
}

.fa-blind:before {
  content: "";
}

.fa-audio-description:before {
  content: "";
}

.fa-volume-control-phone:before {
  content: "";
}

.fa-braille:before {
  content: "";
}

.fa-assistive-listening-systems:before {
  content: "";
}

.fa-american-sign-language-interpreting:before, .fa-asl-interpreting:before {
  content: "";
}

.fa-deaf:before, .fa-deafness:before, .fa-hard-of-hearing:before {
  content: "";
}

.fa-glide:before {
  content: "";
}

.fa-glide-g:before {
  content: "";
}

.fa-sign-language:before, .fa-signing:before {
  content: "";
}

.fa-low-vision:before {
  content: "";
}

.fa-viadeo:before {
  content: "";
}

.fa-viadeo-square:before {
  content: "";
}

.fa-snapchat:before {
  content: "";
}

.fa-snapchat-ghost:before {
  content: "";
}

.fa-snapchat-square:before {
  content: "";
}

.fa-pied-piper:before {
  content: "";
}

.fa-first-order:before {
  content: "";
}

.fa-yoast:before {
  content: "";
}

.fa-themeisle:before {
  content: "";
}

.fa-google-plus-circle:before, .fa-google-plus-official:before {
  content: "";
}

.fa-fa:before, .fa-font-awesome:before {
  content: "";
}

.fa-handshake-o:before {
  content: "";
}

.fa-envelope-open:before {
  content: "";
}

.fa-envelope-open-o:before {
  content: "";
}

.fa-linode:before {
  content: "";
}

.fa-address-book:before {
  content: "";
}

.fa-address-book-o:before {
  content: "";
}

.fa-address-card:before, .fa-vcard:before {
  content: "";
}

.fa-address-card-o:before, .fa-vcard-o:before {
  content: "";
}

.fa-user-circle:before {
  content: "";
}

.fa-user-circle-o:before {
  content: "";
}

.fa-user-o:before {
  content: "";
}

.fa-id-badge:before {
  content: "";
}

.fa-drivers-license:before, .fa-id-card:before {
  content: "";
}

.fa-drivers-license-o:before, .fa-id-card-o:before {
  content: "";
}

.fa-quora:before {
  content: "";
}

.fa-free-code-camp:before {
  content: "";
}

.fa-telegram:before {
  content: "";
}

.fa-thermometer-4:before, .fa-thermometer-full:before, .fa-thermometer:before {
  content: "";
}

.fa-thermometer-3:before, .fa-thermometer-three-quarters:before {
  content: "";
}

.fa-thermometer-2:before, .fa-thermometer-half:before {
  content: "";
}

.fa-thermometer-1:before, .fa-thermometer-quarter:before {
  content: "";
}

.fa-thermometer-0:before, .fa-thermometer-empty:before {
  content: "";
}

.fa-shower:before {
  content: "";
}

.fa-bath:before, .fa-bathtub:before, .fa-s15:before {
  content: "";
}

.fa-podcast:before {
  content: "";
}

.fa-window-maximize:before {
  content: "";
}

.fa-window-minimize:before {
  content: "";
}

.fa-window-restore:before {
  content: "";
}

.fa-times-rectangle:before, .fa-window-close:before {
  content: "";
}

.fa-times-rectangle-o:before, .fa-window-close-o:before {
  content: "";
}

.fa-bandcamp:before {
  content: "";
}

.fa-grav:before {
  content: "";
}

.fa-etsy:before {
  content: "";
}

.fa-imdb:before {
  content: "";
}

.fa-ravelry:before {
  content: "";
}

.fa-eercast:before {
  content: "";
}

.fa-microchip:before {
  content: "";
}

.fa-snowflake-o:before {
  content: "";
}

.fa-superpowers:before {
  content: "";
}

.fa-wpexplorer:before {
  content: "";
}

.fa-meetup:before {
  content: "";
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  width: auto;
  height: auto;
  clip: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

@font-face {
  font-family: FontAwesome;
  src: url("fontawesome-webfont.3981e506.eot");
  src: url("fontawesome-webfont.3981e506.eot") format("embedded-opentype"), url("fontawesome-webfont.58488e7e.woff2") format("woff2"), url("fontawesome-webfont.ed962b83.woff") format("woff"), url("fontawesome-webfont.0caf0c90.ttf") format("truetype"), url("fontawesome-webfont.a9323ae9.svg") format("svg");
  font-display: swap;
}

.glightbox-container {
  width: 100%;
  height: 100%;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  backface-visibility: hidden;
  outline: 0;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 999999 !important;
}

.glightbox-container.inactive {
  display: none;
}

.glightbox-container .gcontainer {
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: relative;
  overflow: hidden;
}

.glightbox-container .gslider {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  transition: transform .4s, -webkit-transform .4s;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  display: flex !important;
}

.glightbox-container .gslide {
  width: 100%;
  opacity: 1;
  user-select: none;
  opacity: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.glightbox-container .gslide.current {
  opacity: 1;
  z-index: 99999;
  position: relative;
}

.glightbox-container .gslide.prev {
  opacity: 1;
  z-index: 9999;
}

.glightbox-container .gslide-inner-content {
  width: 100%;
}

.glightbox-container .ginner-container {
  width: 100%;
  max-width: 100%;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  display: flex;
  position: relative;
}

.glightbox-container .ginner-container.gvideo-container {
  width: 100%;
}

.glightbox-container .ginner-container.desc-bottom, .glightbox-container .ginner-container.desc-top {
  flex-direction: column;
}

.glightbox-container .ginner-container.desc-left, .glightbox-container .ginner-container.desc-right {
  max-width: 100% !important;
}

.gslide iframe, .gslide video {
  min-height: 165px;
  -webkit-overflow-scrolling: touch;
  -ms-touch-action: auto;
  touch-action: auto;
  border: none;
  outline: 0 !important;
}

.gslide:not(.current) {
  pointer-events: none;
}

.gslide-image {
  align-items: center;
}

.gslide-image img {
  max-height: 100vh;
  float: none;
  user-select: none;
  max-width: 100vw;
  width: auto;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  -ms-touch-action: none;
  touch-action: none;
  min-width: 200px;
  border: none;
  outline: 0;
  margin: auto;
  padding: 0;
  display: block;
}

.desc-bottom .gslide-image img, .desc-top .gslide-image img {
  width: auto;
}

.desc-left .gslide-image img, .desc-right .gslide-image img {
  width: auto;
  max-width: 100%;
}

.gslide-image img.zoomable {
  position: relative;
}

.gslide-image img.dragging {
  transition: none;
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
}

.gslide-video {
  max-width: 100vh;
  position: relative;
  width: 100% !important;
}

.gslide-video .gvideo-wrapper {
  width: 100%;
  margin: auto;
}

.gslide-video:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #ff000057;
  display: none;
  position: absolute;
}

.gslide-video.playing:before {
  display: none;
}

.gslide-video.fullscreen {
  min-width: 100%;
  height: 75vh;
  max-width: 100% !important;
}

.gslide-video.fullscreen video {
  max-width: 100% !important;
  width: 100% !important;
}

.gslide-inline {
  text-align: left;
  max-height: calc(100vh - 40px);
  max-width: 100%;
  background: #fff;
  overflow: auto;
}

.gslide-inline .ginlined-content {
  width: 100%;
  padding: 20px;
}

.gslide-inline .dragging {
  transition: none;
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
}

.ginlined-content {
  opacity: 1;
  overflow: auto;
  display: block !important;
}

.gslide-external {
  width: 100%;
  min-width: 100%;
  max-height: 75vh;
  height: 100%;
  background: #fff;
  padding: 0;
  display: flex;
  overflow: auto;
}

.gslide-media {
  width: auto;
  display: flex;
}

.zoomed .gslide-media {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.desc-bottom .gslide-media, .desc-top .gslide-media {
  flex-direction: column;
  margin: 0 auto;
}

.gslide-description {
  flex: 1 0 100%;
  position: relative;
}

.gslide-description.description-left, .gslide-description.description-right {
  max-width: 100%;
}

.gslide-description.description-bottom, .gslide-description.description-top {
  width: 100%;
  margin: 0 auto;
}

.gslide-description p {
  margin-bottom: 12px;
}

.gslide-description p:last-child {
  margin-bottom: 0;
}

.zoomed .gslide-description, .glightbox-button-hidden {
  display: none;
}

.glightbox-mobile .glightbox-container .gslide-description {
  width: 100%;
  max-height: 78vh;
  background: linear-gradient(#0000 0, #000000bf 100%);
  padding: 19px 11px 50px;
  transition: opacity .3s linear;
  position: absolute;
  bottom: 0;
  height: auto !important;
  max-width: 100vw !important;
  order: 2 !important;
  overflow: auto !important;
}

.glightbox-mobile .glightbox-container .gslide-title {
  color: #fff;
  font-size: 1em;
}

.glightbox-mobile .glightbox-container .gslide-desc {
  color: #a1a1a1;
}

.glightbox-mobile .glightbox-container .gslide-desc a {
  color: #fff;
  font-weight: 700;
}

.glightbox-mobile .glightbox-container .gslide-desc * {
  color: inherit;
}

.glightbox-mobile .glightbox-container .gslide-desc string {
  color: #fff;
}

.glightbox-mobile .glightbox-container .gslide-desc .desc-more {
  color: #fff;
  opacity: .4;
}

.gdesc-open .gslide-media {
  opacity: .4;
  transition: opacity .5s;
}

.gdesc-open .gdesc-inner {
  padding-bottom: 30px;
}

.gdesc-closed .gslide-media {
  opacity: 1;
  transition: opacity .5s;
}

.greset {
  transition: all .3s;
}

.gabsolute {
  position: absolute;
}

.grelative {
  position: relative;
}

.glightbox-desc {
  display: none !important;
}

.glightbox-open {
  overflow: hidden;
}

.gloader {
  height: 25px;
  width: 25px;
  z-index: 9999;
  border: 2px solid #fff;
  border-right-color: #0000;
  border-radius: 50%;
  margin: 0 auto;
  animation: lightboxLoader .8s linear infinite;
  display: block;
  position: absolute;
  top: 47%;
  left: 0;
  right: 0;
}

.goverlay {
  width: 100%;
  height: calc(100vh + 1px);
  will-change: opacity;
  background: #000;
  position: fixed;
  top: -1px;
  left: 0;
}

.glightbox-mobile .goverlay {
  background: #000;
}

.gclose, .gnext, .gprev {
  z-index: 99999;
  cursor: pointer;
  width: 26px;
  height: 44px;
  border: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.gclose svg, .gnext svg, .gprev svg {
  width: 25px;
  height: auto;
  margin: 0;
  padding: 0;
  display: block;
}

.gclose.disabled, .gnext.disabled, .gprev.disabled {
  opacity: .1;
}

.gclose .garrow, .gnext .garrow, .gprev .garrow {
  stroke: #fff;
}

.gbtn.focused {
  outline: 2px solid #0f3d81;
}

iframe.wait-autoplay {
  opacity: 0;
}

.glightbox-closing .gclose, .glightbox-closing .gnext, .glightbox-closing .gprev {
  opacity: 0 !important;
}

.glightbox-clean .gslide-description {
  background: #fff;
}

.glightbox-clean .gdesc-inner {
  padding: 22px 20px;
}

.glightbox-clean .gslide-title {
  color: #000;
  margin-bottom: 19px;
  font-family: arial;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.4em;
}

.glightbox-clean .gslide-desc {
  margin-bottom: 0;
  font-family: arial;
  font-size: .86em;
  line-height: 1.4em;
}

.glightbox-clean .gslide-video {
  background: #000;
}

.glightbox-clean .gclose, .glightbox-clean .gnext, .glightbox-clean .gprev {
  background-color: #000000bf;
  border-radius: 4px;
}

.glightbox-clean .gclose path, .glightbox-clean .gnext path, .glightbox-clean .gprev path {
  fill: #fff;
}

.glightbox-clean .gprev {
  width: 40px;
  height: 50px;
  position: absolute;
  top: -100%;
  left: 30px;
}

.glightbox-clean .gnext {
  width: 40px;
  height: 50px;
  position: absolute;
  top: -100%;
  right: 30px;
}

.glightbox-clean .gclose {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 15px;
  right: 10px;
}

.glightbox-clean .gclose svg {
  width: 18px;
  height: auto;
}

.glightbox-clean .gclose:hover {
  opacity: 1;
}

.gfadeIn {
  animation: gfadeIn .5s;
}

.gfadeOut {
  animation: gfadeOut .5s;
}

.gslideOutLeft {
  animation: gslideOutLeft .3s;
}

.gslideInLeft {
  animation: gslideInLeft .3s;
}

.gslideOutRight {
  animation: gslideOutRight .3s;
}

.gslideInRight {
  animation: gslideInRight .3s;
}

.gzoomIn {
  animation: gzoomIn .5s;
}

.gzoomOut {
  animation: gzoomOut .5s;
}

@keyframes lightboxLoader {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes gfadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes gfadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes gslideInLeft {
  from {
    opacity: 0;
    transform: translate3d(-60%, 0, 0);
  }

  to {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes gslideOutLeft {
  from {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    visibility: hidden;
    transform: translate3d(-60%, 0, 0);
  }
}

@keyframes gslideInRight {
  from {
    opacity: 0;
    visibility: visible;
    transform: translate3d(60%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes gslideOutRight {
  from {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(60%, 0, 0);
  }
}

@keyframes gzoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 1;
  }
}

@keyframes gzoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

@media (min-width: 769px) {
  .glightbox-container .ginner-container {
    width: auto;
    height: auto;
    flex-direction: row;
  }

  .glightbox-container .ginner-container.desc-top .gslide-description {
    order: 0;
  }

  .glightbox-container .ginner-container.desc-top .gslide-image, .glightbox-container .ginner-container.desc-top .gslide-image img {
    order: 1;
  }

  .glightbox-container .ginner-container.desc-left .gslide-description {
    order: 0;
  }

  .glightbox-container .ginner-container.desc-left .gslide-image {
    order: 1;
  }

  .gslide-image img {
    max-height: 97vh;
    max-width: 100%;
  }

  .gslide-image img.zoomable {
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
  }

  .zoomed .gslide-image img.zoomable {
    cursor: -webkit-grab;
    cursor: grab;
  }

  .gslide-inline {
    max-height: 95vh;
  }

  .gslide-external {
    max-height: 100vh;
  }

  .gslide-description.description-left, .gslide-description.description-right {
    max-width: 275px;
  }

  .glightbox-open {
    height: auto;
  }

  .goverlay {
    background: #000000eb;
  }

  .glightbox-clean .gslide-media {
    box-shadow: 1px 2px 9px #000000a6;
  }

  .glightbox-clean .description-left .gdesc-inner, .glightbox-clean .description-right .gdesc-inner {
    height: 100%;
    position: absolute;
    overflow-y: auto;
  }

  .glightbox-clean .gclose, .glightbox-clean .gnext, .glightbox-clean .gprev {
    background-color: #00000052;
  }

  .glightbox-clean .gclose:hover, .glightbox-clean .gnext:hover, .glightbox-clean .gprev:hover {
    background-color: #000000b3;
  }

  .glightbox-clean .gprev, .glightbox-clean .gnext {
    top: 45%;
  }
}

@media (min-width: 992px) {
  .glightbox-clean .gclose {
    opacity: .7;
    right: 20px;
  }
}

@media screen and (max-height: 420px) {
  .goverlay {
    background: #000;
  }
}

/*# sourceMappingURL=404.f3c535df.css.map */
